export function useHeaderInformation() {
  const route = useRoute()
  const { t } = useI18n()
  const country = useCountry()
  const ogImage = computed(() => country.isVetmazing.value ? '/logos/vetmazing-pwa-logo.png' : '/logos/heiland-pwa-logo.png')
  const favicon = computed(() => country.isVetmazing.value ? '/icons/favicon-vetmazing.ico' : '/icons/favicon-heiland.ico')
  const titleKey = computed(() => country.isVetmazing.value ? 'layouts.titleVetmazing' : 'layouts.titleHeiland')
  const title = computed(() => t(titleKey.value, { title: t(route.meta.title as string) }))
  const description = computed(() => t('layout.description'))
  return {
    ogImage,
    favicon,
    title,
    description,
  }
}
